.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitepearl;
}

.App-link {
  color: #61dafb;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #008b8b;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #008b8b;
}

/* chat botton */

.react-chatbot-kit-chat-btn-send {
  background-color: #008b8b;
  border-bottom-right-radius: 0%;
}

.bottone {
  background-color: gray;
  /* padding: 5px 12px; */
  border-radius: 4px;
  transition-duration: 0.4s;
}

.button:hover {
  background-color: #4caf50; /* Green */
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-chatbot-kit-chat-container{
  width:100%;
}

.react-chatbot-kit-chat-inner-container{
  height:95vh;
}

.react-chatbot-kit-chat-message-container{
  display: block;
  overflow: auto; 
  height: 100%;
  min-height:95vh;
  max-height: 95vh;
}

.react-chatbot-kit-chat-input-container{
  position:sticky;
}

.react-chatbot-kit-chat-bot-message{
  margin-left: inherit;
  width: inherit;
}

.react-chatbot-kit-chat-bot-message span img{
  width:30vw
}

.react-chatbot-kit-chat-bot-message span a{
  color:#ffffff;
}

.react-chatbot-kit-chat-bot-message a{
  color:#ffffff;
}

.react-chatbot-kit-chat-bot-message img{
  width:100%;
  border-radius:1%;
}

.rating-message{
  color: #000000;
  background-color: #ffffff;
}
